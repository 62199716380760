
import { Vue, Component, Watch } from 'vue-property-decorator';
import {TipoDocumentoService} from '@/core/services/financeiro';
import {TipoDocumento} from '@/core/models/financeiro';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';

@Component
export default class ListaTipoDocumento extends mixins(Vue,ListPage) {

  item = new TipoDocumento();
  service = new TipoDocumentoService();
 
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;

  localBuscaRapida: string = "";
  titulo: string = 'Tipo de Documento';
  subTitulo: string = 'Lista dos tipos de Documentos para o uso no Sistema';
 
  options: any = {
    itemsPerPage: 15
  };

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' },
  ];

 @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
 
    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, undefined, '', 'Nome,id', '').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    .finally(() => (this.loading = false));
  }

  Novo(){
    this.item = new TipoDocumento();
    this.dialogCadastro = true;
  }
  mounted(){
   // this.Atualizar();
  }
 
}

